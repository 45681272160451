export enum Permission {
    HandleInteraction = 1,
    ViewSearch = 2,
    ViewTemplate = 3,
    EditUser = 4,
    EditUserSkill = 5,
    EditUserLanguage = 6,
    EditAgentState = 7,
    EditWidget = 8,
    EditChatBan = 9,
    ToggleWidget = 10,
    EditLanguage = 11,
    EditEmailRoutingRule = 12,
    EditDepartment = 13,
    EditSkill = 14,
    EditTextLocalisation = 15,
    EditSystemSetting = 16,
    EditLogic = 17,
    ViewReporting = 18,
    EditTemplate = 19,
    EditTheme = 20,
    EditForm = 21,
    ViewEmailAssignment = 22,
    EditBrand = 23,
    EditUserBasic = 24,
    RedactContent = 25,
    DeleteAttachment = 26,
    EditFormField = 27,
    AssignInteraction = 28,
    EditRole = 29,
    EditEmailAccount = 30,
}
