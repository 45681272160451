/**
 * In order to enable af feature flag for a specific user, use the following code:
 * import { Clients } from "types-for/client-identification/clients";
 * import { isClient } from "utilities/client-identification";
 *
 * export const FEATURE_FLAG = isClient(Clients.coolbet);
 *
 * NOTE: In order to work with localhost, check the LocalhostClient variable in the file "types-for/client-identification/clients".
 * You can set its value to "all" or to a specific Client to simulate being using that client's environment.
 */
export const DEVICE_AND_LOCATION_DETAILS_ENABLED = true;
export const FORMS_PAGE_ENABLED = true;
export const REDACTIONS_ENABLED = true;
export const DELETE_FORMS_ENABLED = true;
export const FORM_EDITOR_CREATE_SELECT_OPTION_ENABLED = true;
export const FORM_EDITOR_TRANSLATIONS_ENABLED = true;
export const PROACTIVE_CHAT_ENABLED = true;
export const MANAGE_USER_STATES_ENABLED = true;
export const TEXT_LOCALISATIONS_ENABLED = true;
export const LIVE_AGENTS_REPORTING_ENABLED = true;
export const LIVE_AGENTS_FORCE_SIGN_OUT_ENABLED = true;
export const CROSS_DOMAIN_CHATS_ENABLED = true;
export const BRANDS_SCREEN_ENABLED = true;
export const AGENT_NICKNAMES_ENABLED = true;
export const EMAIL_ACCOUNTS_LIST = true;
export const EMAIL_ACCOUNTS_EDIT = true;
export const EMAIL_ACCOUNTS_CREATE = true;
export const EMAIL_ACCOUNTS_ENABLE = true;
export const EMAIL_ACCOUNTS_SEND_TEST_EMAIL = false;
export const BRAND_EMAIL_ADDRESSES = true;
export const GOOGLE_PROVIDER_ENABLED = true;
export const ACTIVE_AGENTS_LANGUAGE_ROLE_FIlTERS_ENABLED = false;
