import { format, parseISO } from "date-fns";
import { DetailsFieldTitle, DetailsFieldValue } from "~/details";
import SplitTimestamp from "~/split-timestamp";

import { IProps } from "./date-details-field.types";

/**
 * Render a date value from the booking details.
 * @param props.value Date value.
 * @returns           Formatted date.
 */
const DateDetailsField = ({ field }: IProps) => (
    <tr className="date-details-field">
        <td>
            <DetailsFieldTitle>{field.title}</DetailsFieldTitle>
        </td>
        <td>
            <DetailsFieldValue
                copyText={
                    typeof field.value === "string" ? format(parseISO(field.value), "dd/MM/yyyy HH:mm") : undefined
                }
                isNull={typeof field.value !== "string"}
                warning={field.warning}
            >
                <SplitTimestamp date={new Date(field.value)} />
            </DetailsFieldValue>
        </td>
    </tr>
);

export default DateDetailsField;
